import investors_exploration_4k from './investors-exploration-4k.webp';
import investors_exploration_wqhd from './investors-exploration-wqhd.webp';
import investors_exploration_1080 from './investors-exploration-1080.webp';
import investors_exploration_720 from './investors-exploration-720.webp';
import investors_exploration_thumbnail from './investors-exploration-thumbnail.webp';

const investorsExplorationImages = {
  thumbnail: investors_exploration_thumbnail,
  '720p': investors_exploration_720,
  '1080p': investors_exploration_1080,
  wqhd: investors_exploration_wqhd,
  '4k': investors_exploration_4k,
};

export const values = [
  investorsExplorationImages['thumbnail'],
  investorsExplorationImages['720p'],
  investorsExplorationImages['1080p'],
  investorsExplorationImages['wqhd'],
  investorsExplorationImages['4k'],
];
export default investorsExplorationImages;
