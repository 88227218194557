import Hero from '../components/Hero';
import { motion } from 'framer-motion';
import Footer from '../components/layout/Footer';
import { values as heroImages } from '../images/hero/about';
import { useEffect } from 'react';
import useHeroMoreContentArrowObserver from '../hooks/use-hero-more-content-arrow-observer';

const About = () => {
  const {ref, showArrow} = useHeroMoreContentArrowObserver();

  useEffect(() => {
    document.title = 'About Hexagon';
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, position: 'absolute' }}
      animate={{ opacity: 1, position: 'relative' }}
      exit={{ opacity: 0, position: 'absolute' }}
      className="motion-div"
    >
      <Hero
        title="About Consumer Portal"
        images={Object.values(heroImages)}
        showMoreContentArrow={showArrow}
      />
      <section className="page-content">
        <div className="container">
          <div className="page-description">
            <p>
              <a
                href={`https://share.vidyard.com/watch/rPTimLZJPHniodi2mgDrwh`}
                target="_blank"
                rel="noreferrer"
              >
                Consumer Portal
              </a>{' '}
              is a simplified, lightweight, and easy-to-use Web client
              application, providing fundamental functionalities for users
              consuming geospatial data. It is available within WebGIS products,
              such as GeoMedia WebMap and Geospatial Portal from Hexagon Safety,
              <span ref={ref}> </span>
              Infrastructure & Geospatial division.
            </p>
            <p>
              WebGIS products bring real-time enterprise data access and
              sophisticated analysis to the web with a fully scalable and
              customizable server solution that deploys high-end web services
              and websites.
            </p>
            <p>
              <a
                href="https://www.hexagongeospatial.com/products/power-portfolio/geomedia-webmap"
                target="_blank"
                rel="noreferrer"
              >
                GeoMedia WebMap
              </a>{' '}
              is a server product for web-based visualization and analysis of
              geospatial data.
            </p>
            <p>
              <a
                href="https://www.hexagongeospatial.com/products/power-portfolio/geomedia-webmap"
                target="_blank"
                rel="noreferrer"
              >
                Geospatial Portal
              </a>{' '}
              is a full-featured, configurable, and customizable thin client
              application that can be used for finding, viewing, querying,
              analyzing, and consuming geospatial data.
            </p>
            <p>
              <a
                href="https://hexagon.com/about"
                target="_blank"
                rel="noreferrer"
              >
                Hexagon
              </a>{' '}
              is a global leader in digital reality solutions, combining sensor,
              software and autonomous technologies. We are putting data to work
              to boost efficiency, productivity, quality and safety across
              industrial, manufacturing, infrastructure, public sector, and
              mobility applications. Our technologies are shaping production and
              people-related ecosystems to become increasingly connected and
              autonomous – ensuring a scalable, sustainable future.
            </p>
            <p>
              <a
                href="https://www.hexagongeospatial.com/about-us"
                target="_blank"
                rel="noreferrer"
              >
                Hexagon’s Safety, Infrastructure & Geospatial
              </a>{' '}
              division improves the resilience and sustainability of the world’s
              critical services and infrastructure. Our solutions turn complex
              data about people, places and assets into meaningful information
              and capabilities for better, faster decision-making in public
              safety, utilities, defense, transportation and government.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </motion.div>
  );
};

export default About;
