import './PageContent.scss';

const PageContent = ({title, children, reference}) => {
  return (
    <section className="page-content">
      <div className="container">
        <div className="page-title">{title}</div>
        <div className="page-description">{children}</div>
      </div>
    </section>
  );
};

export default PageContent;
