import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useUrl } from '../../../hooks/use-url';
import classes from './LinkButton.module.scss';
const LinkButton = (props) => {
  const { to, darkMode, style } = props;

  const linkClass = !!darkMode
    ? `${classes.linkButton} ${classes.dark}`
    : classes.linkButton;

  const { url, isExternal } = useUrl(to);

  return (
    <Fragment>
      {!isExternal && (
        <Link style={style} to={url} className={linkClass}>
          {props.children}
        </Link>
      )}
      {isExternal && (
        <a
          href={url}
          style={style}
          className={linkClass}
          target="_blank"
          rel="noreferrer"
        >
          {props.children}
        </a>
      )}
    </Fragment>
  );
};

export default LinkButton;
