import home_4k from './home-4k.webp';
import home_wqhd from './home-wqhd.webp';
import home_1080 from './home-1080.webp';
import home_720 from './home-720.webp';
import home_thumbnail from './home-thumbnail.webp';

const homeImages = {
  thumbnail: home_thumbnail,
  '720p': home_720,
  '1080p': home_1080,
  wqhd: home_wqhd,
  '4k': home_4k,
};

export const values = [
  homeImages['thumbnail'],
  homeImages['720p'],
  homeImages['1080p'],
  homeImages['wqhd'],
  homeImages['4k'],
];

export default homeImages;
