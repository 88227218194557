import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Hero from '../components/Hero';
import Footer from '../components/layout/Footer';
import ArrowLink from '../components/ui/ArrowLink/ArrowLink';
import PageContent from '../components/layout/PageContent';
import { values as heroImages } from '../images/hero/field-inspection';
import useHeroMoreContentArrowObserver from '../hooks/use-hero-more-content-arrow-observer';

const FieldInspection = ({ demoUrl }) => {
  const {ref, showArrow} = useHeroMoreContentArrowObserver();

  useEffect(() => {
    document.title = 'Field Inspection';
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, position: 'absolute' }}
      animate={{ opacity: 1, position: 'relative' }}
      exit={{ opacity: 0, position: 'absolute' }}
      className="motion-div"
    >
      <Hero
        title="Field Inspection Monitoring"
        images={heroImages}
        navigateTo={demoUrl}
        showMoreContentArrow={showArrow}
      />
      <PageContent title="Field Inspection Demo" >
        <p>
          The waterworks monitoring demo highlights how Hexagon’s Geospatial
          Portal Consumer can be used by various authorities to manage and
          monitor the activities and workflows of field inspectors.
        </p>
        <p>
          <span ref={ref}> </span>
          Field technicians performing asset inspections are equipped with a
          mobile device (smartphone or tablet) with the GeoMedia WebMap Mobile
          app installed on it. During the field inspection,, inspectors can
          update the GIS database. For instance, local water authority
          inspectors, can inspect water meters, enter the meter reading, or
          update additional information. The GeoMedia WebMap Mobile app allows
          inspectors to attach additional documents and multimedia files like
          photos of the inspection site. All provided information is immediately
          sent to the command center. Dispatchers, in the command center can
          monitor and supervise the work of the inspectors with Geospatial
          Portal Consumer . The dispatcher has insight into the infrastructure
          (e.g., water network) status and work progress.
        </p>
        <p>
          The data displayed in the Water Works Monitor Demo Portal represents a
          part of the water supply network in Des Moines, Iowa. What is
          important, the dispatcher has access not only to the spatial data of
          the water supply network with attributes, but also a number of
          statistics. When the Water Works Monitor demo starts, the map showing
          the zones is displayed. The entire inspection area has been divided
          into four zones representing four city areas. The inspectors are
          assigned to zones.
        </p>
        <p>
          With the starting map composition, the dispatcher sees the territorial
          zones and the statistics for every zone. The work progress for each
          zone is presented in the form of a pie chart. In addition, the
          dispatcher receives information about the number of inspected,
          non-inspected and total features too. Statistics for the entire area
          are also available. To see them, it is enough to display the map on a
          smaller scale. To do it quickly, just select the predefined map
          composition -- Works Monitoring Global Statistics -- or simply use the
          zoom in/out buttons if you prefer. On a large-scale map (here you can
          use the predefined Works Monitoring Features View map composition),
          the dispatcher will see detailed GIS data of the demo water supply
          network, including water meters, service lines, main water lines and
          building footprints. Displaying Feature Info dialogue for the water
          meter object shows detailed information provided by the field
          inspector including media files (e.g., water meter photo). Inspected
          water meters are displayed on the map with a green border,
          non-inspected with a red border. Additionally, a label is displayed
          with the last modification date of the inspected object. All of the
          feature listed above give the dispatcher quick insight into the
          network condition and work progress almost in real time.
        </p>
        <p>
          To better understand the data context, user data can be displayed on
          top of the base maps. The following are available: Google Satellite,
          Google Maps – Road Map, Open Street Map. If required, the base map may
          not be displayed at all. Going further, Google Search Places and
          Google Street View services have been integrated with Geospatial
          Portal Consumer. With Google services, it is even better and faster to
          understand utility network data and make the right decisions while
          managing the tasks of inspectors.
        </p>
        <p>
          The Waterworks Monitoring demo is a fully-working instance. Please
          feel free to explore and use all of the commands and features
          available
        </p>
        <ArrowLink to={demoUrl}>Take A Tour</ArrowLink>
      </PageContent>
      <Footer />
    </motion.div>
  );
};

export default FieldInspection;
