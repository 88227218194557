import { createContext, useCallback, useEffect, useState } from 'react';

const MainContext = createContext({
  maxWindowWidth: 0,
  setMaxWindowWidth: () => {},
  externalUrlProtocol: ''
});

export const MainContextProvider = (props) => {
  const [maxWindowWidth, _setMaxWindowWidth] = useState(0);

  const setMaxWindowWidth = useCallback(
    (value) => {
      if (value > maxWindowWidth) {
        _setMaxWindowWidth(value);
      }
    },
    [maxWindowWidth, _setMaxWindowWidth]
  );

  useEffect(() => {
    setMaxWindowWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setMaxWindowWidth(window.innerWidth);
    });
  }, [setMaxWindowWidth]);

  return (
    <MainContext.Provider
      value={{
        maxWindowWidth: maxWindowWidth,
        setMaxWindowWidth: setMaxWindowWidth,
        externalUrlProtocol: new URL(window.location.href).protocol
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContext;
