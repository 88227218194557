import { useRef, useState } from "react";
import useIntersectionObserver from "./use-intersection-observer";

const useHeroMoreContentArrowObserver = (customThreshold = .1) => {
  const [showArrow, setShowArrow] = useState(false);
  const ref = useRef();

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{isIntersecting}]) => {
      setShowArrow(!isIntersecting);
    },
    threshold: customThreshold
  });

  return {ref, showArrow };
}

export default useHeroMoreContentArrowObserver;