import about_4k from './about-4k.webp';
import about_wqhd from './about-wqhd.webp';
import about_1080 from './about-1080.webp';
import about_720 from './about-720.webp';
import about_thumbnail from './about-thumbnail.webp';

const aboutImages = {
  thumbnail: about_thumbnail,
  '720p': about_720,
  '1080p': about_1080,
  wqhd: about_wqhd,
  '4k': about_4k,
};

export const values = [
  aboutImages['thumbnail'],
  aboutImages['720p'],
  aboutImages['1080p'],
  aboutImages['wqhd'],
  aboutImages['4k'],
];

export default aboutImages;
