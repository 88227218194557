import react from 'react';
import { motion } from 'framer-motion';
import Hero from '../components/Hero';
import Footer from '../components/layout/Footer';
import PageContent from '../components/layout/PageContent';
import ArrowLink from '../components/ui/ArrowLink/ArrowLink';
import { values as heroImages } from '../images/hero/municipality-information';
import LazyImage from '../components/ui/LazyImage/LazyImage';
import useHeroMoreContentArrowObserver from '../hooks/use-hero-more-content-arrow-observer';
import {ReactComponent as ExternalLinkIcon} from "../images/common/external_link_icon.svg";

import layerButtonImage from '../images/municipality-information/Layer button.webp';
import floodRiskImage from '../images/municipality-information/Flood risk.webp';
import layersWindowImage from '../images/municipality-information/Layers window.webp';
import addressesAndCadastreImage from '../images/municipality-information/Addresses and cadastre.webp';
import developmentPlansImage from '../images/municipality-information/Development plans.webp';
import forestryAndLandClassesImage from '../images/municipality-information/Forestry and land classes.webp';
import ortophotoAndBlankImage from '../images/municipality-information/Ortophoto and blank.webp';
import technicalInfrastructureImage from '../images/municipality-information/Technical infrastructure.webp';

import layerButtonImageHiRes from '../images/municipality-information/Layer button HiRes.webp';
import floodRiskImageHiRes from '../images/municipality-information/Flood risk HiRes.webp';
import layersWindowImageHiRes from '../images/municipality-information/Layers window HiRes.webp';
import addressesAndCadastreImageHiRes from '../images/municipality-information/Addresses and cadastre HiRes.webp';
import developmentPlansImageHiRes from '../images/municipality-information/Development plans HiRes.webp';
import forestryAndLandClassesImageHiRes from '../images/municipality-information/Forestry and land classes HiRes.webp';
import ortophotoAndBlankImageHiRes from '../images/municipality-information/Ortophoto and blank HiRes.webp';
import technicalInfrastructureImageHiRes from '../images/municipality-information/Technical infrastructure HiRes.webp';

const MunicipalityInformation = ({ demoUrl }) => {
  const {ref, showArrow} = useHeroMoreContentArrowObserver();

  react.useEffect(() => {
    document.title = 'Municipality Information';
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, position: 'absolute' }}
      animate={{ opacity: 1, position: 'relative' }}
      exit={{ opacity: 0, position: 'absolute' }}
      className="motion-div"
    >
      <Hero
        title="Local Municipality Information"
        images={heroImages}
        navigateTo={demoUrl}
        showMoreContentArrow={showArrow}
      />
      <PageContent title="Local Municipality Information Demo">
        <p>
          Geospatial Portal - Consumer is a simplified, lightweight, and
          easy-to-use Web client application, providing fundamental
          <span ref={ref}> </span>
          functionalities for users consuming geospatial data. It provides the
          functions for viewing predefined maps and searching for any kind of
          geospatial information.
        </p>
        <p>
          <b>Local Municipality Information</b> demo instance highlights the key
          functionalities of Geospatial Portal - Consumer relevant for
          establishing powerful GIS solutions on the Web.
        </p>
        Introduced configuration samples, proves that Geospatial Portal -
        Consumer is a very versatile tool in the Spatial Data Infrastructure
        industry. It works not only with Web services created with GeoMedia
        WebMap from Hexagon but also with third-party services, meeting all
        industry standards like INSPIRE or OGC.
        <p>
          <b>The Local Municipality Information</b> demo configuration is based
          on public services provided by national and local (commune) government
          and self-government units.
        </p>
        <p>
          This demo is an example of how local authorities can easily share
          valuable public data with local communities. Residents are, in a
          transparent manner, informed about current and planned investments in
          their neighborhood. By predefined map compositions, they have access
          to cadastral data or the register of public property. One can find
          addresses or check the parcel number. Moreover, presented specialized
          maps of risk occurrence, allow to check whether a given area is safe
          for existing infrastructure or planned investment.
        </p>
        <p>
          The demo instance includes the following predefined map compositions:
        </p>
        <table className="compositions-table">
          <tbody>
            <tr>
              <td>
                <a
                  href={`${demoUrl}/?m=788a2b55-8a63-4079-ac67-d3001e4381be`}
                  target="_blank"
                  rel="noreferrer"
                  title="Move to demo with this composition."
                >
                  <LazyImage
                    images={[developmentPlansImage, developmentPlansImageHiRes]}
                    alt="Development plans"
                  ><ExternalLinkIcon className='externalImageUrl'/></LazyImage>
                </a>
              </td>
              <td>
                Development plans - with this map, it is possible to check
                whether the commune authorities have prepared a spatial
                development plan in a given area, and then what the plan looks
                like, what are the planned investments and where building
                permits have already been issued. For easier navigation and
                locating the plot of interest, the map also contains layers with
                address and cadastral data.
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href={`${demoUrl}/?m=f15f91ac-79c5-408d-b72e-cb760ef68249`}
                  target="_blank"
                  rel="noreferrer"
                  title="Move to demo with this composition."
                >
                  <LazyImage
                    images={[
                      technicalInfrastructureImage,
                      technicalInfrastructureImageHiRes,
                    ]}
                    alt="Technical infrastructure"
                  ><ExternalLinkIcon className='externalImageUrl'/></LazyImage>
                </a>
              </td>
              <td>
                Technical infrastructure - map of utility lines and related
                infrastructure. Data comes from WMS service fetched by utility
                network databases (GESUT).
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href={`${demoUrl}/?m=b0658cb3-7be9-4d7e-a2e7-c444d0c10d58`}
                  target="_blank"
                  rel="noreferrer"
                  title="Move to demo with this composition."
                >
                  <LazyImage
                    images={[floodRiskImage, floodRiskImageHiRes]}
                    alt="Flood risk & ground conditions"
                  ><ExternalLinkIcon className='externalImageUrl'/></LazyImage>
                </a>
              </td>
              <td>
                Flood risk & ground conditions - on this map one check whether
                there is a flood risk in the area, and whether the ground
                conditions are good/suitable for any construction development.
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href={`${demoUrl}/?m=d13941fc-9d27-43c8-ac89-cf1ec4b869f7`}
                  target="_blank"
                  rel="noreferrer"
                  title="Move to demo with this composition."
                >
                  <LazyImage
                    images={[
                      forestryAndLandClassesImage,
                      forestryAndLandClassesImageHiRes,
                    ]}
                    alt="Forestry & land classes"
                   ><ExternalLinkIcon className='externalImageUrl'/></LazyImage>
                </a>
              </td>
              <td>
                Forestry & land classes - with this map, one can check the
                classification of land in a given area, and if the area is
                covered by forests, check the forest district assigned. Such
                information may be valuable when an investment is planned.
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href={`${demoUrl}/?m=e3d5fba9-411c-4890-904b-0a4376aa02d8`}
                  target="_blank"
                  rel="noreferrer"
                  title="Move to demo with this composition."
                >
                  <LazyImage
                    images={[
                      addressesAndCadastreImage,
                      addressesAndCadastreImageHiRes,
                    ]}
                    alt="Addresses and cadastre"
                  ><ExternalLinkIcon className='externalImageUrl'/></LazyImage>
                </a>
              </td>
              <td>
                Addresses & cadastre - map of address points, parcels and
                building register.{' '}
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          The default base map is an Orthophoto layer. By combining map
          compositions with base maps one can compose map views depending on the
          needs or preferences. If required, the base map may not be displayed
          at all.
        </p>
        <LazyImage
          images={[ortophotoAndBlankImage, ortophotoAndBlankImageHiRes]}
          alt="Ortophoto & blank"
          className="municipality municipality_ortophoto-and-blank"
          imagesStyle={{ width: '17rem', maxWidth: '93%', height: '8rem', maxHeight: 'calc(90vw/2)' }}
        />
        <p>
          You can adjust the view of each map to your needs. In the layer
          window, turn a layer on or off, or change the transparency:
        </p>
        <p>
          <LazyImage
            images={[layerButtonImage, layerButtonImageHiRes]}
            alt="Layer button"
            className="municipality municipality_legend-button"
            imagesStyle={{ width: '5rem', height: '5rem'}}
          />
        </p>
        <p>
          <LazyImage
            images={[layersWindowImage, layersWindowImageHiRes]}
            alt="Layers window"
            className="municipality municipality_legend-window"
            imagesStyle={{ width: '30rem', maxWidth: '93%', height: '24rem', maxHeight: 'calc(75vw)' }}
          />
        </p>
        In addition, two search engines are available for the demo setup:
        <ul>
          <li>Cadastral data - to search localization of cadastral parcels.</li>
          <li>
            Geographical names - to search spatial location of an address point,
            street, or town.
          </li>
        </ul>
        <p>
          The data shown in this demo covers the Marki area commune. The Marki
          commune is located in central Poland, in the Masovian region,
          north-east of Warsaw.
        </p>
        <p>
          Beneficiaries of a similar implementation as shown with this demo may
          be local authority department offices. Due to the simplicity,
          intuitiveness, and ease of use of the Geospatial Portal Consumer
          tools, access to the public GIS data becomes easily accessible to the
          citizens.
        </p>
        <p>
          The demo is a fully working instance. Please feel free to explore and
          use all the commands and features available.
        </p>
        <p>
          To learn more about Consumer Portal features please see{' '}
          <a href="https://doc.hexagongeospatial.com/r/yQ09rqQF~BFqZSuISkfPfg/CjpeQijtUj9WY4yXlvqe_w">
            Consumer Portal documentation
          </a>{' '}
          at the{' '}
          <a href="https://doc.hexagongeospatial.com/">
            Hexagon's Documentation Portal.
          </a>
        </p>
        <ArrowLink to={demoUrl}>TAKE A TOUR</ArrowLink>
      </PageContent>
      <Footer />
    </motion.div>
  );
};

export default MunicipalityInformation;
