import classes from './Footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  const innerHeight = window.innerHeight;

  return (
    <section
      className={classes.footer}
      style={{ bottom: `calc(-${innerHeight}px - 2.5em)px` }}
    >
      <div className="container">
        <span>
          ©{year} Hexagon AB and/or its subsidiaries and affiliates. All rights
          reserved.
        </span>
      </div>
    </section>
  );
};

export default Footer;
