import classes from './Feature.module.scss';
import LazyImage from './ui/LazyImage/LazyImage';
import LinkButton from './ui/LinkButton/LinkButton';

const Feature = (props) => {
  const {
    images,
    title,
    description,
    readMoreUrl,
    demoUrl,
    style,
    direction = 'left',
  } = props;

  const directoryClass = direction === 'left' ? classes.left : classes.right;

  return (
    <section className={classes.feature}>
      <div className="container">
        <div className={classes.tainer}>
          <picture>
            <LazyImage images={images} alt="Home" />
          </picture>
          <div
            className={`${classes.background} ${directoryClass}`}
            style={style}
          ></div>
          <div className={`${classes.content} ${directoryClass}`}>
            <h3>{title}</h3>
            <div className={classes.description}>{description}</div>
            <div className={classes.buttons}>
              <LinkButton to={readMoreUrl}>Read more</LinkButton>
              <LinkButton to={demoUrl}>Take a tour</LinkButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
