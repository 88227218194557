import field_inspection_4k from './field-inspection-4k.webp';
import field_inspection_wqhd from './field-inspection-wqhd.webp';
import field_inspection_1080 from './field-inspection-1080.webp';
import field_inspection_720 from './field-inspection-720.webp';
import field_inspection_thumbnail from './field-inspection-thumbnail.webp';

const fieldInspectionImages = {
  thumbnail: field_inspection_thumbnail,
  '720p': field_inspection_720,
  '1080p': field_inspection_1080,
  wqhd: field_inspection_wqhd,
  '4k': field_inspection_4k,
};

export const values = [
  fieldInspectionImages['thumbnail'],
  fieldInspectionImages['720p'],
  fieldInspectionImages['1080p'],
  fieldInspectionImages['wqhd'],
  fieldInspectionImages['4k'],
];

export default fieldInspectionImages;
