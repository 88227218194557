import { motion } from 'framer-motion';
import { useEffect } from 'react';

import Feature from '../components/Feature';
import Hero from '../components/Hero';
import Footer from '../components/layout/Footer';
import ArrowLink from '../components/ui/ArrowLink/ArrowLink';
import PageContent from '../components/layout/PageContent';

import { values as heroImages } from '../images/hero/home';
import { values as investorsExplorationImages } from '../images/hero/investors-exploration';
import { values as fieldInspectionImages } from '../images/hero/field-inspection';
import { values as municipalityInformationImages } from '../images/hero/municipality-information';
import useHeroMoreContentArrowObserver from '../hooks/use-hero-more-content-arrow-observer';

const Home = ({ demoUrls }) => {
  const {ref, showArrow} = useHeroMoreContentArrowObserver();

  useEffect(() => {
    document.title = 'WebGIS All the Things!';
  }, []);

  const fieldInspectionCustomStyle = {
    backgroundColor: 'var(--primary-greenish-blue)',
  };

  const investorsExpolrationCustomStyle = {
    backgroundColor: 'var(--secondary-green)',
  };

  const municipalityInformationCustomStyle = {
    backgroundColor: 'var(--secondary-blue)',
  };

  const [
    fieldInspectionDemoUrl,
    investorsExporationDemoUrl,
    localMunicipalityInformation,
  ] = demoUrls;

  return (
    <motion.div
      initial={{ opacity: 0, position: 'absolute' }}
      animate={{ opacity: 1, position: 'relative' }}
      exit={{ opacity: 0, position: 'absolute' }}
      className="motion-div"
    >
      <Hero
        images={heroImages}
        title="WebGIS All the Things!"
        showMoreContentArrow={showArrow}
      />
      <PageContent title="Geospatial Portal - Consumer">
        <p style={{ marginBottom: 0 }}>
          A simplified, lightweight, and easy-to-use Web client application,
          providing fundamental functionalities for users consuming geospatial
          <span ref={ref}> </span>
          data. It provides the functions for viewing predefined maps and
          searching for any kind of geospatial information. Geospatial Portal –
          Consumer is a very versatile tool in the Spatial Data Infrastructure
          industry. It works not only with Web services created with GeoMedia
          WebMap from Hexagon but also with third-party services, meeting all
          industry standards like INSPIRE or OGC.
        </p>
      </PageContent>
      <Feature
        images={fieldInspectionImages}
        title="Field Inspection Monitoring"
        readMoreUrl="/field-inspection"
        demoUrl={fieldInspectionDemoUrl}
        description="The waterworks monitoring demo highlights how Hexagon’s Geospatial Portal - Consumer can be used by various authorities to manage and monitor the activities and workflows of field inspectors."
        direction="right"
        style={fieldInspectionCustomStyle}
      />
      <Feature
        images={investorsExplorationImages}
        title="Investors Exploration"
        readMoreUrl="/investors-exploration"
        demoUrl={investorsExporationDemoUrl}
        description="Investors Exploration demo instance highlights the key functionalities of Geospatial Portal – Consumer relevant for establishing powerful GIS solutions on the Web. It's mainly based on public services from GUGiK which is the main provider of the most current and official geodetic and cartographic data in Poland."
        direction="left"
        style={investorsExpolrationCustomStyle}
      />
      <Feature
        images={municipalityInformationImages}
        title="Local Municipality Information"
        readMoreUrl="/municipality-information"
        demoUrl={localMunicipalityInformation}
        description="See how citizens can view public GIS data relating to local community life activities, and business information. Open the Local Municipality Information demo and change maps to see cadastral data, plans of development, or locate areas unsuitable for investments, e.g. due to the Flood Risk."
        direction="right"
        style={municipalityInformationCustomStyle}
      />
      <PageContent title="About">
        <p>
          Consumer Portal is available within WebGIS products, that bring
          real-time enterprise data access and sophisticated analysis to the web
          with a fully scalable and customizable server solution that deploys
          high-end web services and websites.
        </p>
        <ArrowLink to="/about">Read More</ArrowLink>
      </PageContent>
      <Footer />
    </motion.div>
  );
};

export default Home;
