import classes from './Hero.module.scss';
import LinkButton from './ui/LinkButton/LinkButton';
import LazyImage from './ui/LazyImage/LazyImage';
import { useEffect, useRef, useState } from 'react';
import { useScroll, motion } from 'framer-motion';

const Hero = (props) => {
  const { images, title, navigateTo, showMoreContentArrow } = props;
  const [heroTranslateY, setHeroTranslateY] = useState(0);
  const sectionRef = useRef();
  const heroPictureRef = useRef();

  const { scrollYProgress } = useScroll({
    target: heroPictureRef,
    offset: ['end start', 'start']
  });

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setHeroTranslateY(((1 - latest) * 100)/3); //changed to "percentage of disappearing" instead of "percentage of appearing" part of element.
    });
  }, [scrollYProgress]);

  const linkButtonCustomStyle = {
    fontSize: '0.3em',
    padding: '0.8em 1.125em 0.7em',
    textShadow: 'none',
  };

  return (
    <section className={classes.hero} ref={sectionRef}>
      <motion.picture>
        <LazyImage
          images={images}
          alt="Home"
          className={classes.containerParallax}
          imagesStyle={{ transform: `translateY(${heroTranslateY}%)` }}
        />
      </motion.picture>
      <div className={classes.background} ref={heroPictureRef} />
      <div className={`${classes.content} container`}>
        <div>{title}</div>
        {navigateTo && (
          <LinkButton
            style={linkButtonCustomStyle}
            to={navigateTo}
            darkMode={true}
          >
            Take A Tour
          </LinkButton>
        )}
      </div>
      <div
        className={`${classes.seeMoreContainer} ${
          !showMoreContentArrow && classes.hideSeeMoreMouse
        }`}
        style={{ position: 'relative' }}
        title="Scroll to down to see content"
      >
        <span className={`${classes.seeMoreArrow} ${classes.arrowOne}`}></span>
        <span className={`${classes.seeMoreArrow} ${classes.arrowTwo}`}></span>
      </div>
    </section>
  );
};

export default Hero;
