import { NavLink, useNavigate } from 'react-router-dom';
import classes from './Header.module.scss';
import companyLogo from '../../images/common/company-logo.svg';
import { useEffect, useState } from 'react';

const Header = () => {
  const mainNavStyle = classes.navlink;
  const activeStyle = `${mainNavStyle} ${classes.navlinkActive}`;
  const navigate = useNavigate();
  const [navbarActive, setNavbarActive] = useState(false);

  const companyLogoClickHandler = () => {
    navigate('/');
  };

  const setNavStyle = ({ isActive }) => {
    return isActive ? activeStyle : mainNavStyle;
  };

  const navbarTogglerClickHandler = () => {
    setNavbarActive((prevState) => !prevState);
  };

  useEffect(() => {
    const closeNavbar = (event) => {
      try {
        const isToggleButton = event.path.some((d) =>
          d.className?.includes(classes.navbarToggler)
        );
        if (!isToggleButton) setNavbarActive(false);
      } catch (err) {
        // nothing special
      }
    };

    document.body.addEventListener('click', closeNavbar);

    return () => document.body.removeEventListener('click', closeNavbar);
  }, []);

  return (
    <header className={classes.header}>
      <nav className={`${classes.navbar} container`}>
        <div className={classes.companyLogo}>
          <img
            src={companyLogo}
            alt="Hexagon"
            onClick={companyLogoClickHandler}
          />
        </div>
        <button
          className={`${classes.navbarToggler} ${
            navbarActive ? classes.active : ''
          }`}
          onClick={navbarTogglerClickHandler}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          className={`${classes.navbarCollapse} ${
            navbarActive ? classes.active : ''
          }`}
        >
          <NavLink to="/" className={setNavStyle}>
            Home
          </NavLink>
          <NavLink to="/field-inspection" className={setNavStyle}>
            Field Inspection
          </NavLink>
          <NavLink to="/investors-exploration" className={setNavStyle}>
            Investors Exploration
          </NavLink>
          <NavLink to="/municipality-information" className={setNavStyle}>
            Municipality Information
          </NavLink>
          <NavLink to="/about" className={setNavStyle}>
            About
          </NavLink>
        </div>
      </nav>
    </header>
  );
};

export default Header;
