import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.module.scss';
import Layout from './components/layout/Layout';
import About from './pages/About';
import FieldInspection from './pages/FieldInspection';
import Home from './pages/Home';
import InvestorsExploration from './pages/InvestorsExploration';
import MunicipalityInformation from './pages/MunicipalityInformation';
import MainContext from './store/MainContext';


const App = () => {
  const location = useLocation();
  
  const { externalUrlProtocol } = useContext(MainContext);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  const fieldInspectionDemoUrl =
    `${externalUrlProtocol}//demo.hexagongeospatial.com/WaterWorksMonitor/`;
  const investorsExporationDemoUrl =
    `${externalUrlProtocol}//demo.hexagongeospatial.com/InvestorsExploration`;
  const localMunicipalityInformation =
    `${externalUrlProtocol}//demo.hexagongeospatial.com/LocalMunicipalityInformation`;

  return (
    <Layout>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <Home
                demoUrls={[
                  fieldInspectionDemoUrl,
                  investorsExporationDemoUrl,
                  localMunicipalityInformation,
                ]}
              />
            }
          />
          <Route
            path="/field-inspection"
            element={<FieldInspection demoUrl={fieldInspectionDemoUrl} />}
          />
          <Route
            path="/investors-exploration"
            element={
              <InvestorsExploration demoUrl={investorsExporationDemoUrl} />
            }
          />
          <Route
            path="/municipality-information"
            element={
              <MunicipalityInformation demoUrl={localMunicipalityInformation} />
            }
          />
          <Route path="/about" element={<About />} />
        </Routes>
      </AnimatePresence>
    </Layout>
  );
}

export default App;
