import { useContext, useEffect, useRef, useState } from 'react';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import MainContext from '../../../store/MainContext';
import classes from './LazyImage.module.scss';
import backgroundImage from './background.webp';

const loadLevelWidthStages = [0, 0, 1280, 1920, 2560];

const LazyImage = ({
  children,
  images,
  alt,
  className,
  divBackgroundClassName,
  imagesStyle,
  containerStyle,
}) => {
  const [maxWidthStage, setMaxWidthStage] = useState(0);
  const [loadedStage, setLoadedStage] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const root = useRef();

  const ctx = useContext(MainContext);

  useEffect(() => {
    const allowedWidthStages = loadLevelWidthStages.filter((element) => {
      return element < ctx.maxWindowWidth;
    }).length;
    if (allowedWidthStages > maxWidthStage) {
      setMaxWidthStage(allowedWidthStages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.maxWindowWidth]);

  useIntersectionObserver({
    target: root,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(root.current);
      }
    },
  });

  const onLoadHandler = (indexOfLoadedImage) => {
    if (indexOfLoadedImage > loadedStage) setLoadedStage(indexOfLoadedImage);
  };

  const imagesContent = images
    .filter((_, index) => {
      const imageIndex = index + 1;
      return maxWidthStage >= imageIndex;
    })
    .map((value, index) => {
      const imageIndex = index + 1;
      return (
        <img
          key={`${alt}_${imageIndex}`}
          className={classes.lazyImage}
          src={value}
          style={{
            opacity: loadedStage >= imageIndex ? 1 : 0,
            visibility: loadedStage <= imageIndex ? 'visible' : 'hidden',
            ...imagesStyle,
          }}
          alt={alt}
          onLoad={onLoadHandler.bind(null, imageIndex)}
        />
      );
    });

  const loadingAnimationElement = (
    <div
      key="loadingAnimEl"
      style={{
        overflow: 'hidden',
        maxWidth: '100%',
        maxHeight: '100%',
        ...imagesStyle,
      }}
      className={classes.loadingAnimationCroppingContainer}
    >
      <div src={backgroundImage} className={classes.loadingAnimation}></div>
    </div>
  );

  imagesContent.splice(
    Math.min(imagesContent.length - 1, 1),
    0,
    loadingAnimationElement
  );

  return (
    <div ref={root} className={className}>
      {isVisible && (
        <>
          <img
            src={backgroundImage}
            alt={alt}
            className={`${classes.loadingBackground} ${divBackgroundClassName}`}
            style={{
              visibility: loadedStage ? 'hidden' : 'visible',
              ...imagesStyle,
            }}
          ></img>
          {imagesContent}
          {children}
        </>
      )}
    </div>
  );
};

export default LazyImage;
