import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Hero from '../components/Hero';
import Footer from '../components/layout/Footer';
import PageContent from '../components/layout/PageContent';
import ArrowLink from '../components/ui/ArrowLink/ArrowLink';
import { values as heroImages } from '../images/hero/investors-exploration';
import investorsExplorationImages from '../images/investors-exploration/investorsExplorationImages';
import investorsExplorationThumbs from '../images/investors-exploration/investorsExplorationThumbs';
import LazyImage from '../components/ui/LazyImage/LazyImage';
import useHeroMoreContentArrowObserver from '../hooks/use-hero-more-content-arrow-observer';

const InvestorsExploration = ({ demoUrl }) => {
  const {ref, showArrow} = useHeroMoreContentArrowObserver();

  useEffect(() => {
    document.title = 'Investors Exploration';
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, position: 'absolute' }}
      animate={{ opacity: 1, position: 'relative' }}
      exit={{ opacity: 0, position: 'absolute' }}
      className="motion-div"
    >
      <Hero
        title="Investors Exploration"
        images={heroImages}
        navigateTo={demoUrl}
        showMoreContentArrow={showArrow}
      />
      <PageContent title="Investors Exploration Demo">
        <p>
          Geospatial Portal - Consumer is a simplified, lightweight, and
          easy-to-use Web client application, providing fundamental
          <span ref={ref}> </span>
          functionalities for users consuming geospatial data. It provides the
          functions for viewing predefined maps and searching for any kind of
          geospatial information. <br />
          <b>Investors Exploration</b> demo instance highlights the key
          functionalities of Geospatial Portal – Consumer relevant for
          establishing powerful GIS solutions on the Web. <br />
          Introduced configuration samples, proves that Geospatial Portal –
          Consumer is a very versatile tool in the Spatial Data Infrastructure
          industry. It works not only with Web services created with GeoMedia
          WebMap from Hexagon but also with third-party services, meeting all
          industry standards like INSPIRE or OGC. <br />
        </p>
        <p>
          The <b>Investors Exploration</b> demo configuration is mainly based on
          public services from GUGiK which is the main provider of the most
          current and official geodetic and cartographic data in Poland.
        </p>
        <p>
          To learn more about GUGiK activities please visit the official Web
          page at{' '}
          <a
            href="https://www.gov.pl/web/gugik-en/"
            target="_blank"
            rel="noreferrer"
          >
            gov.pl/web/gugik-en
          </a>
        </p>
        The core functionality of the demo setup are two search engines:
        <ul>
          <li>Cadastral data – to search localization of cadastral parcels.</li>
          <li>
            Geographical names – to search spatial location of an address point,
            street or town.{' '}
          </li>
        </ul>
        The demo instance includes three predefined map compositions:
        <ul>
          <li>
            Cadastral data - map of parcels and building register. Map data are
            served by WMS service (National Integration of Land records).
          </li>
          <li>
            Technical infrastructure – map of utility lines and related
            infrastructure. Data comes from WMS service fetched by utility
            network databases (GESUT).
          </li>
          <li>
            General map – map of general geographic objects such as buildings,
            roads, rivers, etc.
          </li>
        </ul>
        <p>
          In addition, all predefined map compositions have a DEM layer defined.
          The basic digital elevation model for Poland is based on a 1 m × 1 m
          mesh and allows to get height and coordinates at a given point.
          Obtained DTM data, as well as thematic attribute data (e.g.,
          information about parcels), are presented in the Feature Info
          dialogue.
        </p>
        <p>
          The default base map is an Orthophoto layer. The other base map, that
          users can choose, is Rzeźba terenu (Digital Surface Model). The
          Digital Surface Model (DSM) represents terrain surface along with any
          objects.
          <br />
          By combining map compositions with base maps raster users can compose
          map views depending on their needs or preferences. If required, the
          base map may not be displayed at all. <br />
          Services from GUGiK used to build maps for the demo, cover the entire
          territory of Poland, although the predefined maps show sample dataset
          in the center of Łodz.
        </p>
        <p>
          The rich set of data combined with Geospatial Portal Consumer may be
          very useful for all entities which activities are based on access to
          spatial data infrastructure.
        </p>
        <p>
          Beneficiaries of a similar implementation as shown with this demo may
          be any kind of planning offices, crisis management departments,
          offices dealing with spatial management at the local and national
          level, real estate offices and the like ventures. Due to the
          simplicity, intuitiveness, and ease of use of the Geospatial Portal
          Consumer tools, access to the public GIS data becomes easily
          accessible to the citizens.
        </p>
        <p>
          The demo is a fully working instance. Please feel free to explore and
          use all of the commands and features available.
        </p>
        <p>
          To learn more about Consumer Portal features please see{' '}
          <a
            href="https://doc.hexagongeospatial.com/r/tGyOpFIXPQOtZMP2laGn9w/6G9oYGp7iSXQnWczxzsYMg"
            target="_blank"
            rel="noreferrer"
          >
            Consumer Portal documentation
          </a>{' '}
          at the{' '}
          <a
            href="https://doc.hexagongeospatial.com/"
            target="_blank"
            rel="noreferrer"
          >
            Hexagon’s Documentation Portal.
          </a>
        </p>
        <br />
        <h5>Usage sample</h5>
        <p>
          Let’s imagine you want to buy a parcel of land in the countryside. You
          got an interesting offer from a real estate agency. The only
          localization data you have is the village name, the parcel number and
          the municipality: <i>Kalonka 127/26 ; municipality Nowosolna</i>. You
          want to do some research about the property.
        </p>
        <p>Are you wondering where the town called Kalonka is?</p>
        To check it out:
        <ol>
          <li>
            Run <b>Investors Exploration</b> Geospatial Portal Consumer demo:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.firstTutorial.one,
                investorsExplorationImages.firstTutorial.one,
              ]}
              alt=""
            />
          </li>
          <li>
            Ensure the <b>Geographical Names</b> search is enabled:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.firstTutorial.two,
                investorsExplorationImages.firstTutorial.two,
              ]}
              alt=""
            />
          </li>
          <li>
            Into the search field type <i>Kalonka</i> and click the loupe icon:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.firstTutorial.three_one,
                investorsExplorationImages.firstTutorial.three_one,
              ]}
              alt=""
            />
            <br />
            The search result is displayed:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.firstTutorial.three_two,
                investorsExplorationImages.firstTutorial.three_two,
              ]}
              alt=""
            />
          </li>
          <li>
            Click the result on the list. The map will be zoomed in/out to the
            exact location and the pin showing the found place will be displayed
            on the map:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.firstTutorial.four,
                investorsExplorationImages.firstTutorial.four,
              ]}
              alt=""
            />
          </li>
        </ol>
        <div className="walkthrough-separator"></div>
        Now, when you know where the Kalonka village is located let’s find the
        given parcel.
        <ol>
          <li>
            Ensure the <b>Cadastral Data</b> composition is displayed:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.secondTutorial.one,
                investorsExplorationImages.secondTutorial.one,
              ]}
              alt=""
            />
          </li>
          <li>
            Ensure the <b>Cadastral Data</b> search source is enabled:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.secondTutorial.two,
                investorsExplorationImages.secondTutorial.two,
              ]}
              alt=""
            />
          </li>
          <li>
            Type <i>Kalonka 127/26</i> into the search field. All parcels
            matching the search criteria are listed:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.secondTutorial.three,
                investorsExplorationImages.secondTutorial.three,
              ]}
              alt=""
            />
            <br />
            Note: to find a parcel you can also type the “Teryt code” or its
            part if you know it. The “Teryt code” may looks like in the
            following sample: 100608_2.0006.127/26.
          </li>
          <li>
            Click the result on the list. The map view will be zoomed in to the
            requested parcel and it will be marked on the map with the pin:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.secondTutorial.four,
                investorsExplorationImages.secondTutorial.four,
              ]}
              alt=""
            />
            <br />
            The <b>Cadastral Data</b> predefined map composition shows geodetic
            boundaries of parcels and buildings.
          </li>
        </ol>
        <div className="walkthrough-separator"></div>
        Are you wondering whether the parcel you are interested is equipped with
        utilities like electricity, water line, gas lines, etc.?
        <ol>
          <li>
            Select <b>Technical Infrastructure</b> map composition:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.thirdTutorial.one,
                investorsExplorationImages.thirdTutorial.one,
              ]}
              alt=""
            />
            <br />
            Now you can see all technical infrastructure around:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.thirdTutorial.two,
                investorsExplorationImages.thirdTutorial.two,
              ]}
              alt=""
            />
            <br />
            An important feature of any real estate affecting its attractiveness
            and further development possibilities is the topography and relief.
            The topography is visible on the Orthophoto base map (default).
          </li>
        </ol>
        <div className="walkthrough-separator"></div>
        To see the land relief and whether the parcel and the neighbour area is
        flat or hilly:
        <ol>
          <li>
            Change the base map to <b>Terrain Surface</b>:<br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.fourthTutorial.one_one,
                investorsExplorationImages.fourthTutorial.one_one,
              ]}
              alt=""
            />
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.fourthTutorial.one_two,
                investorsExplorationImages.fourthTutorial.one_two,
              ]}
              alt=""
            />
          </li>
        </ol>
        <div className="walkthrough-separator"></div>
        In order to get more attribute information about the parcel including
        the height and coordinates:
        <ol>
          <li>
            Activate <b>Feature Info</b> tool:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.fifthTutorial.one,
                investorsExplorationImages.fifthTutorial.one,
              ]}
              alt=""
            />
          </li>
          <li>
            Click anywhere within the parcel boundary. All information served by
            the connected services will be displayed in appropriate tabs of the{' '}
            <b>Feature Info</b> window:
            <br />
            <LazyImage
              className="walkthrough_image-container"
              images={[
                investorsExplorationThumbs.fifthTutorial.two,
                investorsExplorationImages.fifthTutorial.two,
              ]}
              alt=""
            />
          </li>
        </ol>
        <p>
          This is how you can get a lot of valuable information with just a few
          clicks in the <b>Investors Exploration</b> demo.
        </p>
        <ArrowLink to={demoUrl}>TAKE A TOUR</ArrowLink>
      </PageContent>
      <Footer />
    </motion.div>
  );
};

export default InvestorsExploration;
