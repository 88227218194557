import first_one from './first_tutorial/1_thumb.webp';
import first_two from './first_tutorial/2_thumb.webp';
import first_three_one from './first_tutorial/3.1_thumb.webp';
import first_three_two from './first_tutorial/3.2_thumb.webp';
import first_four from './first_tutorial/4_thumb.webp';

import second_one from './second_tutorial/1_thumb.webp';
import second_two from './second_tutorial/2_thumb.webp';
import second_three from './second_tutorial/3_thumb.webp';
import second_four from './second_tutorial/4_thumb.webp';

import third_one from './third_tutorial/1_thumb.webp';
import third_two from './third_tutorial/2_thumb.webp';

import fourth_one_one from './fourth_tutorial/1.1_thumb.webp';
import fourth_one_two from './fourth_tutorial/1.2_thumb.webp';

import fifth_one from './fifth_tutorial/1_thumb.webp';
import fifth_two from './fifth_tutorial/2_thumb.webp';

const investorsExplorationThumbs = {
  firstTutorial: {
    one: first_one,
    two: first_two,
    three_one: first_three_one,
    three_two: first_three_two,
    four: first_four,
  },
  secondTutorial: {
    one: second_one,
    two: second_two,
    three: second_three,
    four: second_four,
  },
  thirdTutorial: {
    one: third_one,
    two: third_two,
  },
  fourthTutorial: {
    one_one: fourth_one_one,
    one_two: fourth_one_two,
  },
  fifthTutorial: {
    one: fifth_one,
    two: fifth_two,
  },
};

export default investorsExplorationThumbs;
