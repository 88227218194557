import municipality_information_4k from './municipality-information-4k.webp';
import municipality_information_wqhd from './municipality-information-wqhd.webp';
import municipality_information_1080 from './municipality-information-1080.webp';
import municipality_information_720 from './municipality-information-720.webp';
import municipality_information_thumbnail from './municipality-information-thumbnail.webp';

const municipalityInformationImages = {
  thumbnail: municipality_information_thumbnail,
  '720p': municipality_information_720,
  '1080p': municipality_information_1080,
  wqhd: municipality_information_wqhd,
  '4k': municipality_information_4k,
};

export const values = [
  municipalityInformationImages['thumbnail'],
  municipalityInformationImages['720p'],
  municipalityInformationImages['1080p'],
  municipalityInformationImages['wqhd'],
  municipalityInformationImages['4k'],
];

export default municipalityInformationImages;
