import { Fragment } from 'react';
import Header from './Header';
import classes from './Layout.module.scss';

const Layout = (props) => {
  return (
    <Fragment>
      <Header />
      <div className={classes.content}>{props.children}</div>
    </Fragment>
  );
};

export default Layout;
